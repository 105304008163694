<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="diaVencimiento"
                type="text"
                label="Día de vencimiento"
                dense
                outlined
                :rules="rules.validDay.concat(rules.required)"
                v-mask="'##'"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-autocomplete
                v-model="terminacionCuit"
                label="Terminación de CUIT"
                dense
                outlined
                :multiple="!isEdit"
                item-text="value"
                item-value="id"
                :items="terminacionesCUITItems"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-text-field
                type="text"
                v-model="periodoDesde"
                label="Período desde"
                dense
                outlined
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                :rules="
                  rules.required.concat(
                    [
                      !(periodoDesde && periodoHasta) ||
                        periodoDesde <= periodoHasta ||
                        'Formato incorrecto'
                    ],
                    rules.periodoYyyyMm
                  )
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-text-field
                v-model="periodoHasta"
                label="Período hasta"
                dense
                outlined
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                :rules="
                  rules.required.concat(
                    [
                      !(periodoDesde && periodoHasta) ||
                        periodoDesde <= periodoHasta ||
                        'Formato incorrecto'
                    ],
                    rules.periodoYyyyMm
                  )
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditDDJJVencimientoCuit",
  directives: { mask },
  props: {
    vencCuitObject: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_VENC_CUIT,
    newTitle: enums.titles.NUEVO_VENC_CUIT,
    rules: rules,
    periodoDesde: null,
    periodoHasta: null,
    diaVencimiento: null,
    terminacionesCUITItems: [
      { id: 0, value: 0 },
      { id: 1, value: 1 },
      { id: 2, value: 2 },
      { id: 3, value: 3 },
      { id: 4, value: 4 },
      { id: 5, value: 5 },
      { id: 6, value: 6 },
      { id: 7, value: 7 },
      { id: 8, value: 8 },
      { id: 9, value: 9 }
    ],
    terminacionCuit: [],
    ddjjVencimientoId: null
  }),
  computed: {
    isEdit() {
      return this.vencCuitObject ? true : false;
    }
  },
  created() {
    if (this.vencCuitObject) {
      this.setVencCuit(this.vencCuitObject);
    } else {
      this.newVencCuit();
    }
  },
  methods: {
    ...mapActions({
      saveVencimientoDDJJ: "configuracion/saveVencimientoDDJJ",
      setAlert: "user/setAlert"
    }),

    async setVencCuit() {
      this.ddjjVencimientoId = this.vencCuitObject.ddjjVencimientoId;
      this.terminacionCuit = this.vencCuitObject.terminacionCuit;
      this.diaVencimiento = this.vencCuitObject.diaVencimiento;
      this.periodoDesde = this.vencCuitObject.periodoDesde;
      this.periodoHasta = this.vencCuitObject.periodoHasta;
    },
    async newVencCuit() {
      this.formEditTitle = this.newTitle;
    },
    async saveEdit() {
      const data = this.vencCuitObject
        ? {
            ddjjVencimientoId: this.ddjjVencimientoId,
            diaVencimiento: this.diaVencimiento,
            terminacionCuit: this.terminacionCuit,
            periodoDesde: this.periodoDesde,
            periodoHasta: this.periodoHasta
          }
        : {
            ddjjVencimientoId: this.ddjjVencimientoId,
            diaVencimiento: this.diaVencimiento,
            terminacionesCuit: this.terminacionCuit,
            periodoDesde: this.periodoDesde,
            periodoHasta: this.periodoHasta
          };
      const response = await this.saveVencimientoDDJJ(data);
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style></style>
