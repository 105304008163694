var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveEdit()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"type":"text","label":"Día de vencimiento","dense":"","outlined":"","rules":_vm.rules.validDay.concat(_vm.rules.required)},model:{value:(_vm.diaVencimiento),callback:function ($$v) {_vm.diaVencimiento=$$v},expression:"diaVencimiento"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"label":"Terminación de CUIT","dense":"","outlined":"","multiple":!_vm.isEdit,"item-text":"value","item-value":"id","items":_vm.terminacionesCUITItems},model:{value:(_vm.terminacionCuit),callback:function ($$v) {_vm.terminacionCuit=$$v},expression:"terminacionCuit"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"type":"text","label":"Período desde","dense":"","outlined":"","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","rules":_vm.rules.required.concat(
                  [
                    !(_vm.periodoDesde && _vm.periodoHasta) ||
                      _vm.periodoDesde <= _vm.periodoHasta ||
                      'Formato incorrecto'
                  ],
                  _vm.rules.periodoYyyyMm
                )},model:{value:(_vm.periodoDesde),callback:function ($$v) {_vm.periodoDesde=$$v},expression:"periodoDesde"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Período hasta","dense":"","outlined":"","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","rules":_vm.rules.required.concat(
                  [
                    !(_vm.periodoDesde && _vm.periodoHasta) ||
                      _vm.periodoDesde <= _vm.periodoHasta ||
                      'Formato incorrecto'
                  ],
                  _vm.rules.periodoYyyyMm
                )},model:{value:(_vm.periodoHasta),callback:function ($$v) {_vm.periodoHasta=$$v},expression:"periodoHasta"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }