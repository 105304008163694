<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-card>
          <v-data-table
            :headers="headers"
            :items="itemsVencCuit"
            dense
            class="elevation-1"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      @click="openModal()"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModal(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar registro</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteVencCuit(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar registro</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0 "
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="60%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditDDJJVencimientoCuit
        :vencCuitObject="vencCuit"
        @closeAndReload="closeAndReload"
      ></EditDDJJVencimientoCuit>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditDDJJVencimientoCuit from "@/components/modules/cuotas/configuracion/EditDDJJVencimientoCuit.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    EditDDJJVencimientoCuit,
    Ayuda,
    GoBackBtn
  },
  name: "DDJJVencimientoCuit",
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    search: "",
    showDeleteModal: false,
    itemToDelete: null,
    routeToGo: "FiscalizacionDeAportes",
    title: enums.titles.VENCIMIENTO_CUIT,
    optionCode: enums.webSiteOptions.VENCIMIENTO_CUIT,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    titleDelete: "¿Eliminar cuit?",
    rules: rules,
    itemsVencCuit: [],
    vencCuit: {},
    headers: [
      {
        text: "Terminación de CUIT",
        value: "terminacionCuit",
        align: "start",
        sortable: true
      },
      {
        text: "Día de vencimiento",
        value: "diaVencimiento",
        sortable: false
      },
      {
        text: "Período desde",
        value: "periodoDesde",
        sortable: false
      },
      {
        text: "Período hasta",
        value: "periodoHasta",
        sortable: false
      },

      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    openModalEdit: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadCuit();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getVencimientosDDJJ: "configuracion/getVencimientosDDJJ",
      deleteDdjjVencimientoCuit: "configuracion/deleteDdjjVencimientoCuit",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_VENC_IVA:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_VENC_IVA:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVO_VENC_IVA:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    async loadCuit() {
      const data = await this.getVencimientosDDJJ();
      this.itemsVencCuit = data;
    },
    deleteVencCuit(item) {
      this.showDeleteModal = true;
      this.itemToDelete = item;
    },
    async confirmDelete() {
      const response = await this.deleteDdjjVencimientoCuit({
        ddjjVencimientoId: this.itemToDelete.ddjjVencimientoId
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadCuit();
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.vencCuit = item;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.vencCuit = {};
      this.loadCuit();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped></style>
